<template>
	<nav>
		<SidebarCompanyNav
				v-if="companyId"
				:company-id="companyId"
				:company-name="companyName"
				:rie-id="rieId"
				:rie-name="rieName"
		/>
		<SideMenu
				v-if="authenticated && !companyId"
				:menu-items="[{to: {name: pageNames.companiesOverview}, text: 'Bedrijven'}]"
		/>

		<SideMenu
				v-if="authenticated && companyId && rieId"
				:menu-items="menuItemsRie"
				menu-style="primary"
				class="menu-items-rie"
		/>

		<SideMenu
				v-if="authenticated && companyId"
				:menu-items="menuItemsCompany"
				menu-style="secondary"
		/>

		<SideMenu
				:menu-items="menuItemsLogin"
				menu-style="secondary"
		/>
	</nav>
</template>
<script setup lang="ts">
	import {computed} from 'vue';
	import {useRuntimeConfig} from '#app';
	import {pageNames} from '~/lib/pageHelper';
	import SidebarCompanyNav from '~/components/layout/SidebarCompanyNav.vue';
	import SideMenu from '~/components/layout/menu/SideMenu.vue';
	import type {MenuEntry} from '~/lib/types/MenuTypes';

	const props = defineProps({
		authenticated: {
			type: Boolean,
			required: true
		},
		companyId: {
			type: String,
			required: false,
			default: undefined
		},
		companyName: {
			type: String,
			required: false,
			default: undefined
		},
		rieId: {
			type: [String, Number],
			required: false,
			default: undefined
		},
		rieName: {
			type: String,
			required: false,
			default: undefined
		},
		loginUrl: {
			type: String,
			required: false,
			default: undefined
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		allowedToWriteToCompany: {
			type: Boolean,
			required: false,
			default: false
		}
	});

	const menuItemsRie = computed<Array<MenuEntry>>(() => {
		const items = [
			{
				to: {
					name: pageNames.rieDashboard,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Dashboard'
			},
			{
				to: {
					name: pageNames.inventarisation,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Inventarisatie'
			},
			{
				to: {
					name: pageNames.riskOverview,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Risico\'s'
			},
			{
				to: {
					name: pageNames.actionOverview,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Plan van aanpak'
			},
			{
				to: {
					name: pageNames.reportOverview,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Rapportage'
			},
			{
				to: {
					name: pageNames.responsiblesOverview,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Verantwoordelijken'
			}
		];

		if (useRuntimeConfig().public.type === 'stigas') {
			items.push({
				to: {
					name: pageNames.stigasQuestionnaireOverview,
					params: {companyId: props.companyId, rieId: props.rieId}
				},
				text: 'Werknemers­vragenlijst'
			});
		}

		return items;
	});

	const menuItemsCompany = computed<MenuEntry[]>(() => {
		const usersMenuItem = props.allowedToWriteToCompany
			? [
				{
					to: {
						name: pageNames.companyUsers,
						params: {companyId: props.companyId}
					},
					text: 'Gebruikers'
				}
			]
			: [];

		return [
			{
				to: {
					name: pageNames.rieOverview,
					params: {companyId: props.companyId}
				},
				text: 'RI&E\'s'
			},
			{
				to: {
					name: pageNames.companyDetail,
					params: {companyId: props.companyId}
				},
				text: 'Bedrijfsinformatie'
			},
			...usersMenuItem
		];
	});

	const menuItemsLogin = computed<MenuEntry[]>(() => {
		const helpMenuItem = {
			to: {name: pageNames.help},
			text: 'Help'
		};

		if (props.loading) {
			return [
				helpMenuItem,
				{
					text: 'Laden...'
				}
			];
		}

		if (props.authenticated) {
			return [
				helpMenuItem,
				{
					to: {name: pageNames.logout},
					text: 'Uitloggen'
				}
			];
		}
		return [
			helpMenuItem,
			{
				to: props.loginUrl ? props.loginUrl : {name: pageNames.login},
				text: 'Inloggen'
			}
		];
	});
</script>

<style scoped>
	nav {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	.menu-items-rie {
		margin-top: -20px;
	}
</style>
